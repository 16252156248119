// Gallery.jsx
import React from 'react';
import {Row,Card} from 'react-bootstrap';
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";

export default function Gallery() {
    return (
        <Layout>
            <h1>Gallery</h1>

            <h2>Japan</h2>
            <Row xs={1} md={2} className="g-4">
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryJapan1974">
                        <Card.Title><StaticImage src="../images/gallery/Japan1974/1056501_orig.jpg"
                                                 alt="Japan 1974 - 1987"/></Card.Title>
                        <Card.Body>
                            <Card.Title>Japan 1974 - 1987</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
            </Row>

            <h2>Australia</h2>
            <Row xs={1} md={2} className="g-4">
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryAustralia1980">
                        <Card.Title><StaticImage src="../images/gallery/Australia1980/2207599_orig.jpg"
                                                 alt="Australia 1980 - 1990"/></Card.Title>
                        <Card.Body>
                            <Card.Title>Australia 1980 - 1990</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryAustralia1990">
                        <Card.Title><StaticImage src="../images/gallery/Australia1990/7125182_orig.jpg"
                                                 alt="Australia 1990 - 2000"/></Card.Title>
                        <Card.Body>
                            <Card.Title>Australia 1990 - 2000</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryAustralia2000">
                        <Card.Title><StaticImage src="../images/gallery/Australia2000/October2010/4136238_orig.jpg"
                                                 alt="Australia 2000 - 2010"/></Card.Title>
                        <Card.Body>
                            <Card.Title>Australia 2000 - 2010</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>

                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryAustralia2014">
                        <Card.Title><StaticImage src="../images/gallery/Australia2014/685560_orig.jpg"
                                                 alt="Australia 2014"/></Card.Title>
                        <Card.Body>
                            <Card.Title>Australia 2014</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
            </Row>

            <h2>United Kingdom</h2>
            <Row xs={1} md={2} className="g-4">
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryUK1970">
                        <Card.Title><StaticImage src="../images/gallery/UK1970/5038505_orig.jpg"
                                                 alt="UK 1970 - 1990"/></Card.Title>
                        <Card.Body>
                            <Card.Title>UK 1970 - 1990</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryUK1990">
                        <Card.Title><StaticImage src="../images/gallery/UK1990/8438454_orig.jpg"
                                                 alt="UK 1990 - 2000"/></Card.Title>
                        <Card.Body>
                            <Card.Title>UK 1990 - 2000</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
                <Card style={{width: '20rem'}}>
                    <Card.Link href="/GalleryUK2000">
                        <Card.Title><StaticImage src="../images/gallery/UK2000/Danshagradings2010/9999840_orig.jpg"
                                                 alt="UK 2000 - 2010"/></Card.Title>
                        <Card.Body>
                            <Card.Title>UK 2000 - 2010</Card.Title>
                        </Card.Body>
                    </Card.Link>
                </Card>
            </Row>
        </Layout>
    );
}

